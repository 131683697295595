import React, { useState, useEffect } from "react";
import cls from "./Loader.module.css";

const Loader = () => {
  const [statusIndex, setStatusIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(60); // Инициализация таймера на 60 секунд
  const [isComplete, setIsComplete] = useState(false); // Состояние завершения таймера
  const [fadeOut, setFadeOut] = useState(false); // Состояние для плавного исчезновения

  const statusMessages = [
    "Открываю завесу тайны...",
    "Соединяюсь с энергией Вселенной...",
    "Собираю силы для пророчества...",
    "Вглядываюсь в таинственные символы...",
    "Настраиваюсь на потоки судьбы...",
    "Вызываю силу звездных светил...",
    "Внимательно слушаю шепот карт...",
    "Проникаю в тайны неизведанного...",
    "Чувствую дыхание древних миров..."
  ];

  // Смена сообщений статуса
  useEffect(() => {
    const interval = setInterval(() => {
      setStatusIndex((prevIndex) => (prevIndex + 1) % statusMessages.length);
    }, 3100);

    return () => clearInterval(interval);
  }, []);

  // Таймер обратного отсчета
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setFadeOut(true);
      setTimeout(() => setIsComplete(true), 1000); // Устанавливаем "готово" после исчезновения
    }
  }, [timeLeft]);

  return (
    <div className={cls.loader__wrapper}>
      <span className={cls.loader}></span>
      <div className={cls.statusBar}>{statusMessages[statusIndex]}</div>
      <div className={cls.progressWrapper}>
        <div className={cls.progressBar}></div>
      </div>
      {!isComplete && (
        <div
          className={cls.timer}
          style={{ opacity: fadeOut ? 0 : 1, transition: "opacity 1s" }}
        >
          Оставшееся время: {timeLeft} секунд
        </div>
      )}
      {isComplete && <div className={cls.completeMessage}>Приготовьтесь услышать ответ.</div>}
    </div>
  );
};

export default Loader;
